<template>
  <div class="d-flex flex-column py-6">
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8" xl="6">
        <h1 class="text-h4 secondary--text font-weight-bold mb-3">
          <span>Crie sua conta em nossa plataforma</span>
        </h1>

        <div class="text-body-1 font-weight-bold mb-8">
          <span>
            Bem vindo!<br />
            Por favor preencha os campos com seus detalhes.
          </span>
        </div>

        <v-form v-model="valid" ref="form">
          <div @keypress.enter.prevent="submit()" class="mb-6">
            <custom-input
              v-model="form.name"
              label="Nome"
              placeholder="Digite seu nome completo"
              outlined
            />

            <custom-input
              v-model="form.email"
              label="E-mail"
              placeholder="Digite seu e-mail"
              outlined
            />

            <custom-input
              v-model="form.password"
              label="Senha"
              placeholder="Digite sua senha"
              :append-icon="
                showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              :type="showPassword ? 'text' : 'password'"
              outlined
              @click:append="showPassword = !showPassword"
            />

            <custom-input
              v-model="form.confirmPassword"
              label="Confirmação de senha"
              placeholder="Digite novamente sua senha"
              :append-icon="
                showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              :type="showPassword ? 'text' : 'password'"
              outlined
              @click:append="showPassword = !showPassword"
            />

            <v-checkbox class="ml-2" v-model="terms" :rules="rules">
              <template v-slot:label>
                <p class="mb-0">
                  Eu aceito com os
                  <a class="mx-1" @click.stop.prevent="openTerms()">
                    termos de aceite, condições de uso e contratual
                  </a>
                  da plataforma.
                </p>
              </template>
            </v-checkbox>
          </div>

          <v-btn
            class="mb-6 rounded-xl secondary--text"
            color="primary2"
            block
            large
            @click="submit()"
          >
            Criar conta
          </v-btn>

          <div class="d-flex justify-center text-center">
            <router-link to="/auth" class="text-decoration-none text-body-1">
              <span>
                <span class="overtext--text font-weight-bold">
                  Já possui uma conta?
                </span>
                <span class="font-weight-bold secondary--text">
                  Entrar agora
                </span>
              </span>
            </router-link>
          </div>
        </v-form>
      </v-col>
    </v-row>

    <loader-hover v-if="loading" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { request } from "@/services";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      showPassword: false,
      terms: false,
      form: {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        inviteCode: "",
      },
    };
  },

  beforeMount() {
    let inviteCode = this.$route.query.invite_code;
    if (!!inviteCode) this.form.inviteCode = inviteCode;
  },

  computed: {
    rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },
  },

  methods: {
    ...mapActions(["setToken", "setUser"]),

    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "registerUser",
          nome: this.form.name,
          email: this.form.email,
          pass: this.form.password,
          invite_id: this.form.inviteCode || "",
        };

        await request(payload).then(async (res) => {
          this.setToken(res.authToken);
          this.getUser();
        });
      } catch (err) {
        this.displayAlert(err.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async getUser() {
      try {
        const payload = {
          method: "getUserData",
        };

        await request(payload).then((res) => {
          this.setUser(res.userData);
          this.$router.push({ path: "/account" });
        });
      } catch (err) {
        // err
      }
    },

    openTerms() {
      window.open("https://earthguard.com.br/termos-de-uso/", "_blank").focus();
    },

    displayAlert,
  },
};
</script>

<style></style>
